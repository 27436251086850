import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NotAuthenticated from "../components/MyPage/NotAuthenticated"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"

const SecondPage = () => {
  const isAuthenticated = useIsAuthenticated()

  console.log(isAuthenticated)

  // if (isAuthenticated) {
  //   return <Redirect to="/hefinn" />
  // }

  return (
    <div>
      <Seo title="Page two" />
      <h1>Hi from the second page</h1>
      <p>Welcome to page 2</p>
      <Link to="/">Go back to the homepage</Link>
      <br />
      <NotAuthenticated />
    </div>
  )
}

export default SecondPage
